/* You can add global styles to this file, and also import other style files */
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-notifications/styles/material.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';

@import './theme/fonts.scss';
@import 'colors.scss';
@import './theme/data-grid.scss';
@import './theme/buttons.scss';
@import './theme/inputs.scss';
@import './theme/calendars.scss';

html,
body,
#dialog-container {
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  width: 100%;
}

body {
  color: var(--bark);
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-weight: normal;
}

h1 {
  font-family: 'swedbank_headlineblack';
  color: var(--identity);
  margin: 0 0 20px;
}

.e-spinner-pane .e-spinner-inner .e-spin-material {
  stroke: var(--turquoise);
}

.e-time-zone-container,
.e-all-day-container,
.e-repeat-container,
.e-recurrenceeditor {
  display: none;
}

.e-schedule .e-timeline-month-view .e-content-wrap table tr:last-child td {
  border-bottom-width: 1px !important;
}

.e-schedule .e-timeline-view .e-content-wrap table tr:last-child td {
  border-bottom-width: 1px !important;
}

.e-schedule .e-timeline-week-view .e-content-wrap table tr:last-child td {
  border-bottom-width: 1px !important;
}

// .e-control .e-dialog .e-lib .e-quick-dialog .e-dlg-modal .e-popup .e-popup-open

#QuickDialog {
  width: 440px;
}

// .e-control .e-dialog .e-lib .e-quick-dialog .e-dlg-modal .e-popup .e-popup-open {
//   width: 440px;
// }

.digitalIcon {
  background-repeat: no-repeat;
  // background-position: 5px -151px;
  background-position: 'center';
  background-size: 30px;
  background-image: url('./assets/Account_orange.png');
}

.e-grid .e-rowdragheader {
  background-color: #ecf9f3 !important;
}

.brand-image img {
  width: 161px;
}

/* hide icon in month-view */
.e-schedule .e-timeline-month-view .e-block-indicator {
  display: none;
}

form {
  div[fxlayout='column'] {
    position: relative;
  }
  .error-message {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: transparent !important;
    color: #f44336;
    font-style: italic;
    font-size: 0.8em important;
    position: absolute;
    bottom: -20px;
    right: 0px;
  }
}
