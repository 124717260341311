@import './colors.scss';

.e-schedule .e-month-view .e-current-date .e-date-header,
.e-schedule .e-month-agenda-view .e-curre nt-date .e-date-header,
.e-schedule .e-month-view .e-appointment,
.e-schedule .e-vertical-view .e-day-wrapper .e-appointment,
.e-schedule .e-timeline-view .e-appointment,
.e-schedule .e-timeline-month-view .e-appointment {
  background-color: var(--primary);
}

.e-schedule .e-month-view .e-date-header-wrap table td.e-current-day,
.e-schedule .e-month-agenda-view .e-date-header-wrap table td.e-current-day,
.e-schedule .e-vertical-view .e-clone-time-indicator,
.e-schedule .e-vertical-view .e-current-time,
.e-schedule .e-vertical-view .e-header-cells.e-current-day,
.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
.e-schedule .e-schedule-toolbar .e-active-view .e-icons,
.e-schedule .e-timeline-view .e-date-header-wrap table td.e-current-day,
.e-schedule .e-timeline-month-view .e-date-header-wrap table td.e-current-day,
.e-schedule .e-timeline-view .e-clone-time-indicator,
.e-schedule .e-timeline-view .e-current-time {
  color: var(--secondary);
}

.e-schedule .e-vertical-view .e-previous-timeline,
.e-schedule .e-vertical-view .e-current-timeline,
.e-schedule .e-timeline-view .e-current-timeline {
  border-color: var(--secondary);
}

.e-schedule .e-vertical-view .e-date-header-wrap table tbody td,
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text,
.e-schedule .e-timeline-view .e-date-header-wrap table td,
.e-schedule .e-timeline-month-view .e-date-header-wrap table td,
.e-schedule .e-timeline-view .e-resource-text,
.e-schedule .e-timeline-month-view .e-resource-text,
.e-schedule .e-timeline-view .e-resource-tree-icon,
.e-schedule .e-timeline-month-view .e-resource-tree-icon {
  color: var(--bark);
}

.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day,
.e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected.e-focused-date span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected.e-focused-date span.e-day {
  background-color: var(--identity);
}
