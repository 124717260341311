/*
https://brand-manager.swedbank.com/start/guidelines/visual-identity/colours.html
https://brand-manager.swedbank.com/start/guidelines/visual-identity/digital-design/colour-system.html
https://brand-manager.swedbank.com/start/guidelines/visual-identity/colours/our-colour-palette.html
*/

/* Primary brand colour and tints */

:root {
  --identity: #ff5f00; // main identity colour, swedbank-orange
  --identity-hover: #d95100; // main identity colour, swedbank-orange-hover
  --identity-text: #fb4f00; // deep tint, swedbank-orange-text
  --primary: #ff9100; // mid tint, tangerine
  --primary-medium: #fdc92a; // mid tint, pineapple
  --primary-light1: #fff1cd; // light tint, peach
  --primary-light2: #fdf6ee; // light tint, apricot

  /* Complementary colours and tints */

  --secondary: #31a3ae; // main, turquoise
  --secondary-dark: #257886; // deep tint, teal
  --secondary-medium1: #8acdc3; // mid tint, seafoam
  --secondary-medium2: #bbead9; // mid tint, aqua
  --secondary-light1: #dbf8ed; // light tint, sky
  --secondary-light2: #ecf9f3; // light tint, mist

  --tertiary: #c5569a; // main, lilac
  --tertiary-dark: #990066; // deep tint, tulip
  --tertiary-medium1: #d28ac5; // mid tint, lavender
  --tertiary-medium2: #f8dbf5; // mid tint, iris
  --tertiary-light1: #ffecff; // light tint, rose
  --tertiary-light2: #fbf3f7; // light tint, thistle

  /* Functionality colours */

  --bark: #512b2b;
  --white: #ffffff;
}
