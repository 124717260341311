/*
https://brand-manager.swedbank.com/start/guidelines/visual-identity/colours.html
https://brand-manager.swedbank.com/start/guidelines/visual-identity/digital-design/colour-system.html
https://brand-manager.swedbank.com/start/guidelines/visual-identity/colours/our-colour-palette.html
*/
/* Primary brand colour and tints */
:root {
  --identity: #ff5f00;
  --identity-hover: #d95100;
  --identity-text: #fb4f00;
  --primary: #ff9100;
  --primary-medium: #fdc92a;
  --primary-light1: #fff1cd;
  --primary-light2: #fdf6ee;
  /* Complementary colours and tints */
  --secondary: #31a3ae;
  --secondary-dark: #257886;
  --secondary-medium1: #8acdc3;
  --secondary-medium2: #bbead9;
  --secondary-light1: #dbf8ed;
  --secondary-light2: #ecf9f3;
  --tertiary: #c5569a;
  --tertiary-dark: #990066;
  --tertiary-medium1: #d28ac5;
  --tertiary-medium2: #f8dbf5;
  --tertiary-light1: #ffecff;
  --tertiary-light2: #fbf3f7;
  /* Functionality colours */
  --bark: #512b2b;
  --white: #ffffff;
}