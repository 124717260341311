@import './colors.scss';

.e-btn {
  font-family: 'swedbank_headlinebold';
  border-radius: 2px;
  font-size: 15px;
  background: var(--secondary);
  color: var(--white);
  text-transform: none;
  padding: 8px 16px 6px;
}

.e-btn:hover {
  color: var(--white);
  background-color: #298790;
}

.e-btn:active {
  color: var(--white);
  background-color: #267e86;
}

.e-btn:focus {
  color: var(--white);
  background-color: #31a3ae;
}

.e-btn:disabled {
  background-color: rgba(75, 174, 184, 0.4);
  border-color: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}

.e-btn.e-primary,
.e-css.e-btn.e-primary {
  background: var(--identity);
}

.e-btn.e-primary:hover,
.e-css.e-btn.e-primary:hover {
  background-color: #d95100;
}

.e-btn.e-primary:active,
.e-css.e-btn.e-primary:active {
  background-color: #cc4c00;
}

.e-btn.e-primary:focus,
.e-css.e-btn.e-primary:focus {
  background-color: #ff5f00;
}

.e-btn.e-primary:disabled,
.e-css.e-btn.e-primary:disabled {
  background-color: #ff9659;
}
