@import './colors.scss';

label {
  color: var(--bark);
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  margin-bottom: 10px;
  display: block;
}

.e-input-group,
.e-input-group.e-control-wrapper,
input.e-input,
input.e-input:focus,
textarea.e-input,
textarea.e-input:focus {
  border-bottom-width: 2px;
  border-color: #d4c4bc;
  background: var(--white);
  color: var(--bark);
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  padding: 10px;
  width: auto;
  margin-bottom: 0;
}

.e-input-group,
.e-input-group.e-control-wrapper {
  padding: 5px 10px;
}

.e-input:focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) {
  border-color: var(--secondary);
}

.e-multiselect .e-delim-values,
.e-multiselect .e-multi-select-wrapper .e-chips .e-chipcontent {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  -webkit-text-fill-color: var(--bark);
}

.e-multi-select-wrapper .e-chips {
  background-color: var(--secondary-light1);
  margin-top: 2px;
  margin-bottom: 2px;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):hover,
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):hover {
  border-bottom-width: 2px;
  border-color: #d4c4bc;
  margin-bottom: 0;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
  background: var(--secondary);
}

ejs-multiselect.e-error {
  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):hover,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):hover {
    border-color: #f44336;
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
    background: #f44336;
  }
}

.e-radio:checked + label::before {
  border-color: var(--identity);
}

.e-radio:checked + label:hover::before {
  border-color: var(--identity-hover);
}

.e-radio:checked + label::after {
  background-color: var(--identity);
  color: var(--identity);
}

.e-radio:checked + label:hover::after {
  background-color: var(--identity-hover);
  color: var(--identity-hover);
}
