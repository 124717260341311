@import './colors.scss';

.e-grid {
  border-left: none;
  border-right: none;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.e-grid .e-gridheader {
  border-top: none;
  border-bottom: none;
}

.e-grid .e-headercell {
  background: var(--secondary-light2);
  font-weight: bold;
  color: var(--bark);
}

.e-grid .e-row .e-rowcell,
.e-grid .e-rowcell:not(.e-editedbatchcell):not(.e-updatedtd) {
  color: var(--bark);
}

.e-pager .e-numericitem,
.e-grid .e-gridpager .e-prevpagedisabled,
.e-grid .e-gridpager .e-prevpage,
.e-grid .e-gridpager .e-nextpage,
.e-grid .e-gridpager .e-nextpagedisabled,
.e-grid .e-gridpager .e-lastpagedisabled,
.e-grid .e-gridpager .e-lastpage,
.e-grid .e-gridpager .e-firstpage,
.e-grid .e-gridpager .e-firstpagedisabled {
  color: var(--bark);
}

.e-grid .e-gridpager .e-currentitem {
  background-color: var(--secondary);
  color: var(--white);
}

.e-grid .e-btn.e-icon-btn,
.e-grid .e-css.e-btn.e-icon-btn {
  border-radius: 50%;
}

.e-grid .e-unboundcelldiv .e-icons {
  color: var(--bark);
}
